import React from 'react';
import GoogleMapReact from 'google-map-react';

function Map() {
    const renderMarkers = (map: any, maps: any) => {
        return new maps.Marker({
            position: { lat: 42.09270631670863, lng: -88.24878399451258 },
            map,
            title: 'Our Yard',
        });
    };

    const data = {
        center: {
            lat: 42.09270631670863,
            lng: -88.24878399451258,
        },
        zoom: 10,
    };

    return (
        <div style={{ height: '500px', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: 'AIzaSyDY5mBBFM74sRn7wsnOMQYO_HphlbXEtPA',
                }}
                defaultCenter={data.center}
                defaultZoom={data.zoom}
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            />
        </div>
    );
}

export default Map;
