import React from 'react';
import Layout from '../../components/layout/layout';
import Footer from '../../components/footer/footer';
import SimpleReactLightbox from 'simple-react-lightbox';
import Gallery from '../../components/gallery/gallery';
import HeaderCarousel from '../../components/headerCarousel/headerCarousel';
import image1 from '../../assets/images/gallery/1.jpg';
import image2 from '../../assets/images/gallery/2.jpg';
import image4 from '../../assets/images/gallery/4.jpg';
import image5 from '../../assets/images/gallery/5.jpg';
import image6 from '../../assets/images/gallery/6.jpg';
import image7 from '../../assets/images/gallery/7.jpg';
import image10 from '../../assets/images/gallery/10.jpg';
import image11 from '../../assets/images/gallery/11.jpg';
import image13 from '../../assets/images/gallery/13.jpg';
import image14 from '../../assets/images/gallery/14.jpg';
import image17 from '../../assets/images/gallery/17.jpg';

function Photo() {
    const slides = [
        {
            src: image1,
        },
        {
            src: image2,
        },
        {
            src: image4,
        },
        {
            src: image5,
        },
        {
            src: image6,
        },
        {
            src: image7,
        },
        {
            src: image10,
        },
        {
            src: image11,
        },
        {
            src: image13,
        },
        {
            src: image14,
        },
        {
            src: image17,
        },
    ];

    return (
        <Layout
            header={<HeaderCarousel slideList={slides} />}
            content={
                <SimpleReactLightbox>
                    <Gallery />
                </SimpleReactLightbox>
            }
            footer={<Footer />}
        />
    );
}

export default Photo;
