import React, { FC, ReactElement, Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from '../pages/home/home';
import NotFound from '../pages/notFound/notFound';
import Apply from '../pages/apply/apply';
import Photo from '../pages/photo/photo';

const AppRoutes: FC = (): ReactElement => {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/apply" element={<Apply />} />
                    <Route path="/photos" element={<Photo />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default AppRoutes;
