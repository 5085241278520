import React, { createContext, useMemo, useState } from 'react';
import AppRoutes from './routes';

export interface AppContent {
    translation: string;
    setTranslation: () => void;
}

export const AppContext = createContext<AppContent>({
    translation: '',
    setTranslation: () => {},
});

function App() {
    const [translation, setTranslation] = useState('en');
    const value = useMemo(() => ({ translation, setTranslation }), [translation]);

    return (
        // @ts-ignore
        <AppContext.Provider value={value}>
            <AppRoutes />
        </AppContext.Provider>
    );
}

export default App;
