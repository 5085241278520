import React, { ReactElement } from 'react';

interface props {
    header: ReactElement<any, any>;
    content?: ReactElement<any, any>;
    footer?: ReactElement<any, any>;
}

function Layout({ header, content, footer }: props) {
    return (
        <>
            <div className="wrapper">
                <header className="header">{header}</header>
                <main className="content">{content}</main>
            </div>
            <footer className="footer">{footer}</footer>
        </>
    );
}

export default Layout;
