import React, { useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../../assets/images/logo3.png';
import { useLocation, useNavigate } from 'react-router-dom';
import './navigationBar.scss';

export interface Navigation {
    title: string;
    to: string;
}

function NavigationBar() {
    const navigationList: Array<Navigation> = [
        { title: 'Home', to: '/' },
        { title: 'Photos', to: '/photos' },
        { title: 'Apply', to: '/apply' },
    ];

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const navigateTo = (to: string) => {
        navigate(to, { replace: true });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Navbar variant="dark" expand="lg" className="navbar-top">
            <Container>
                <Navbar.Brand onClick={() => navigateTo('/')}>
                    <img src={logo} className="d-inline-block align-top logo" alt="IST Group" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {navigationList.map((item, index) => {
                            return (
                                <Nav.Link
                                    key={index}
                                    className={pathname == item.to ? 'active' : ''}
                                    onClick={() => navigateTo(item.to)}
                                >
                                    {item.title}
                                </Nav.Link>
                            );
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;
