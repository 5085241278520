import React from 'react';
import { Container, Row } from 'react-bootstrap';
import InformationBar from '../informationBar/informationBar';
import NavigationBar from '../navigationBar/navigationBar';
import './headerStatic.scss';

function HeaderStatic() {
    return (
        <div className="header-static">
            <Container fluid>
                <Row md={12}>
                    <InformationBar />
                    <NavigationBar />
                </Row>
            </Container>
        </div>
    );
}

export default HeaderStatic;
