import React, { MutableRefObject, useRef, useState } from 'react';
import Layout from '../../components/layout/layout';
import Footer from '../../components/footer/footer';
import HeaderStatic from '../../components/headerStatic/headerStatic';
import { Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';
import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';
import './aplly.scss';

interface Data {
    firstName: string;
    lastName: string;
    phone: string;
    city: string;
    state: string;
    experience: string;
    email: string;
    comments: string;
    cdl: string;
    dl_number: string;
    startDate: Date;
    'g-recaptcha-response': string;
}

function Apply() {
    const { t } = useTranslation();

    const states = [
        { title: 'Alabama', name: 'AL' },
        { title: 'Alaska', name: 'AK' },
        { title: 'Arizona', name: 'AZ' },
        { title: 'Arkansas', name: 'AR' },
        { title: 'California', name: 'CA' },
        { title: 'Colorado', name: 'CO' },
        { title: 'Connecticut', name: 'CT' },
        { title: 'Delaware', name: 'DE' },
        { title: 'Florida', name: 'FL' },
        { title: 'Georgia', name: 'GA' },
        { title: 'Hawaii', name: 'HI' },
        { title: 'Idaho', name: 'ID' },
        { title: 'Illinois', name: 'IL' },
        { title: 'Indiana', name: 'IN' },
        { title: 'Iowa', name: 'IA' },
        { title: 'Kansas', name: 'KS' },
        { title: 'Kentucky', name: 'KY' },
        { title: 'Louisiana', name: 'LA' },
        { title: 'Maine', name: 'ME' },
        { title: 'Maryland', name: 'MD' },
        { title: 'Massachusetts', name: 'MA' },
        { title: 'Michigan', name: 'MI' },
        { title: 'Minnesota', name: 'MN' },
        { title: 'Mississippi', name: 'MS' },
        { title: 'Missouri', name: 'MO' },
        { title: 'Montana', name: 'MT' },
        { title: 'Nebraska', name: 'NE' },
        { title: 'Nevada', name: 'NV' },
        { title: 'New Hampshire', name: 'NH' },
        { title: 'New Jersey', name: 'NJ' },
        { title: 'New Mexico', name: 'NM' },
        { title: 'New York', name: 'NY' },
        { title: 'North Carolina', name: 'NC' },
        { title: 'North Dakota', name: 'ND' },
        { title: 'Ohio', name: 'OH' },
        { title: 'Oklahoma', name: 'OK' },
        { title: 'Oregon', name: 'OR' },
        { title: 'Pennsylvania', name: 'PA' },
        { title: 'Rhode Island', name: 'RI' },
        { title: 'South Carolina', name: 'SC' },
        { title: 'South Dakota', name: 'SD' },
        { title: 'Tennessee', name: 'TN' },
        { title: 'Texas', name: 'TX' },
        { title: 'Utah', name: 'UT' },
        { title: 'Vermont', name: 'VT' },
        { title: 'Virginia', name: 'VA' },
        { title: 'Washington', name: 'WA' },
        { title: 'West Virginia', name: 'WV' },
        { title: 'Wisconsin', name: 'WI' },
        { title: 'Wyoming', name: 'WY' },
        { title: 'District of Columbia', name: 'DC' },
    ];

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Data>();

    const [captcha, setCaptcha] = useState(false);
    const recaptchaRef = useRef<ReCAPTCHA>() as MutableRefObject<ReCAPTCHA>;

    const submitHandle = (data: Data) => {
        submit(data);
        reset();
        setCaptcha(false);
        recaptchaRef.current.reset();
    };

    const submit = (data: Data) => {
        const preparedData = {
            ...data,
            'g-recaptcha-response': recaptchaRef.current?.getValue(),
        };
        // @ts-ignore
        emailjs.send('service_6lm8zo3', 'template_ua2sy75', preparedData, 'NxBaVGr7dEAWuWSnn').then(
            (result) => console.log(result.text),
            (error) => console.log(error.text)
        );
    };

    const onCaptchaChange = (value: string | null) => {
        if (value) setCaptcha(true);
    };

    return (
        <Layout
            header={<HeaderStatic />}
            content={
                <div className="apply-wrapper">
                    <Container fluid className="py-5">
                        <Row className="m-4 apply-row">
                            <div className="col-lg-1" />
                            <div className="col-12 col-lg-4">
                                <h2 className="title">{t('apply_title')}</h2>
                                <h5 className="description-header">{t('apply_description_header')}</h5>
                                <dl className="conditions">
                                    <li>{t('apply_description_content_one')}</li>
                                    <li>{t('apply_description_content_second')}</li>
                                    <li>{t('apply_description_content_third')}</li>
                                    <li>{t('apply_description_content_fourth')}</li>
                                    <li>{t('apply_description_content_fifth')}</li>
                                    <li>{t('apply_description_content_six')}</li>
                                    <li>{t('apply_description_content_seven')}</li>
                                    <li>{t('apply_description_content_eight')}</li>
                                    <li>{t('apply_description_content_nine')}</li>
                                    <li>{t('apply_description_content_ten')}</li>
                                    <li>{t('apply_description_content_eleven')}</li>
                                </dl>
                            </div>
                            <div className="form-container col-12 col-lg-6">
                                <Form className="d-flex flex-column p-5 form" onSubmit={handleSubmit(submitHandle)}>
                                    <Form.Group className="d-flex flex-column mt-2">
                                        <Form.Control
                                            className="form-field h-50"
                                            type="text"
                                            placeholder="Email*"
                                            {...register('email', {
                                                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                required: true,
                                            })}
                                        />
                                        {errors.email?.type === 'required' && (
                                            <p className="invalid mt-2 mb-0">Email is required</p>
                                        )}
                                        {errors.email?.type === 'pattern' && (
                                            <p className="invalid mt-2 mb-0">Please enter correct Email</p>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column mt-2">
                                        <Form.Control
                                            className="form-field h-50"
                                            type="text"
                                            placeholder="First Name*"
                                            {...register('firstName', { required: true })}
                                        />
                                        {errors.firstName?.type === 'required' && (
                                            <p className="invalid mt-2 mb-0">First Name is required</p>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column mt-2">
                                        <Form.Control
                                            className="form-field h-50"
                                            type="text"
                                            placeholder="Last Name*"
                                            {...register('lastName', { required: true })}
                                        />
                                        {errors.lastName?.type === 'required' && (
                                            <p className="invalid mt-2 mb-0">Last Name is required</p>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column mt-2">
                                        <Form.Control
                                            className="form-field h-50"
                                            type="text"
                                            placeholder="Phone Number*"
                                            {...register('phone', {
                                                pattern: /^\d+$/,
                                                required: true,
                                            })}
                                        />
                                        {errors.phone?.type === 'required' && (
                                            <p className="invalid mt-2 mb-0">Phone number is required</p>
                                        )}
                                        {errors.phone?.type === 'pattern' && (
                                            <p className="invalid mt-2 mb-0">Please enter correct Phone number</p>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column mt-2">
                                        <Form.Control
                                            className="form-field h-50"
                                            type="text"
                                            placeholder="City/Country*"
                                            {...register('city', { required: true })}
                                        />
                                        {errors.city?.type === 'required' && (
                                            <p className="invalid mt-2 mb-0">City is required</p>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column mt-2">
                                        <Form.Select
                                            className="form-field h-50"
                                            placeholder="State*"
                                            {...register('state')}
                                        >
                                            <option key="choose-your-state" hidden value="">
                                                Choose your state
                                            </option>
                                            {states.map((item, index) => (
                                                <option key={index} value={item.title}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column mt-2">
                                        <Form.Control
                                            className="form-field h-50"
                                            type="text"
                                            placeholder="Years of experience in car hauling"
                                            {...register('experience')}
                                        />
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column mt-2">
                                        <Form.Select className="form-field h-50" placeholder="CDL" {...register('cdl')}>
                                            <option key="1" hidden value="">
                                                Do you have CDL
                                            </option>
                                            <option key="2" value="yes">
                                                Yes
                                            </option>
                                            <option key="3" value="no">
                                                No
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column mt-2">
                                        <Form.Control
                                            className="form-field h-50"
                                            type="text"
                                            placeholder="DL #/State issued"
                                            {...register('dl_number')}
                                        />
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column mt-2 date-container">
                                        <Form.Control
                                            className="form-field h-50"
                                            type="date"
                                            placeholder="Date you can start"
                                            min={moment().format('YYYY-MM-DD')}
                                            {...register('startDate')}
                                        />
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column mt-2">
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            className="form-field"
                                            placeholder="Comments"
                                            {...register('comments')}
                                        />
                                    </Form.Group>
                                    <ReCAPTCHA
                                        className="captcha"
                                        ref={recaptchaRef}
                                        sitekey="6Leis6chAAAAAN5sEKdAkLGhr1uWw_CwHH7-591G"
                                        onChange={onCaptchaChange}
                                    />
                                    <div className="d-flex flex-column mt-2">
                                        <button type="submit" disabled={!captcha} className="submit-button py-2 mt-2">
                                            Apply
                                        </button>
                                    </div>
                                </Form>
                            </div>
                            <div className="col-lg-1" />
                        </Row>
                    </Container>
                </div>
            }
            footer={<Footer />}
        />
    );
}

export default Apply;
