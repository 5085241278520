import React from 'react';
import Layout from '../../components/layout/layout';
import HeaderCarousel from '../../components/headerCarousel/headerCarousel';
import Footer from '../../components/footer/footer';
// import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function NotFound() {
    // const { t } = useTranslation();

    const slides = [
        {
            src: '../../assets/images/01.jpg',
            title: 'World most Fastest secure delivery service 1',
        },
        {
            src: '../../assets/images/02.jpg',
            title: 'World most Fastest secure delivery service 2',
        },
        {
            src: '../../assets/images/03.jpg',
            title: 'World most Fastest secure delivery service 3',
        },
    ];

    return (
        <Layout
            header={<HeaderCarousel slideList={slides} />}
            content={
                <div>
                    <h2>Nothing to see here!</h2>
                    <p>
                        <Link to="/">Go to the home page</Link>
                    </p>
                </div>
            }
            footer={<Footer />}
        />
    );
}

export default NotFound;
