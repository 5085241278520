export const TRANSLATIONS_EN = {
    first_pros_title: 'WE ARE PERFORMANCE DRIVEN',
    first_pros_content: `Achieve greatness, and that is just a beginning of our story. Our team’s relentless efforts and 
    dedicative work lay foundation for your absolute success and satisfaction. We rise above mediocrity, and by letting 
    us serve you, so will YOU!`,
    second_pros_title: 'WE ARE PASSIONATE ABOUT OUR DRIVERS',
    second_pros_content: `
        Welcome and thank you for your interest. We are a commercial transportation company that proudly 
        and diligently serves businesses throughout the continental US. We 
        carry out each transaction with scrupulous attention to detail to 
        ensure that your needs are met at the highest level. We are a 
        company where learning and self-improvement never stops and a company that offers not just a job, but a purpose.
    `,
    third_pros_title: 'WE ARE SUCCESSFUL',
    third_pros_content: `
        Our mission is simple, regardless of your transportation needs, it is our job to always guarantee impeccable services, so YOU can reach 
        unparalleled success. Our number one goal is to provide the highest level of transportation services while ensuring integrity, fairness, 
        diligence and courtesy to our customers and business partners. Our company consists of impactful leaders and influential 
        team members who each combine their diverse set of skills in order to exceed all of your transportation needs and deliver performance
        at the highest level.
    `,
    fourth_pros_title: 'WE ARE PASSIONATE ABOUT OUR DRIVERS',
    fourth_pros_content: `
       Welcome and thank you for your interest in. We are a commercial transportation company that proudly and diligently 
       serves businesses throughout the continental US. We carry out each transaction with scrupulous attention to detail to 
       ensure that your needs are met at the highest level. We are a company where learning and self-improvement never stops and a 
       company that offers not just a job, but a purpose.
    `,
    apply_title: 'We hire with CDL and without',
    apply_description_header: 'We offer:',
    apply_description_content_one: 'Weekly salary from $ 2,500 to $ 3,500',
    apply_description_content_second: 'Flexible schedule',
    apply_description_content_third: 'New pickups',
    apply_description_content_fourth: 'Bonuses',
    apply_description_content_fifth: 'New trailers',
    apply_description_content_six: 'Experienced dispatchers',
    apply_description_content_seven: 'Fuel cards',
    apply_description_content_eight: 'Midwest / East Coast routes.',
    apply_description_content_nine: 'Round-the-clock support 24/7',
    apply_description_content_ten: 'Does not require experience / Paid training',
    apply_description_content_eleven: 'We pay for the hotel if you drive through the weekend',
    transaction_title_eng: 'ENG',
    transaction_title_ua: 'UA',
    transaction_title_ru: 'RU',
    footer_about_title: 'About us',
    footer_about_description:
        'Welcome and thank you for your interest in IST GROUP. Inc. We are a commercial ' +
        'transportation company that proudly and diligently serves businesses throughout the continental US.',
};
