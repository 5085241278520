import React, { useRef, useState } from 'react';
import { Button, Container, Overlay, Row, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navigation } from '../navigationBar/navigationBar';
import './footer.scss';

function Footer() {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const navigationList: Array<Navigation> = [
        { title: 'Home', to: '/' },
        { title: 'Photos', to: '/photos' },
        { title: 'Apply', to: '/apply' },
    ];

    const [show, setShow] = useState({ telephone: false, email: false, address: false });
    const targetTelephone = useRef(null);
    const targetEmail = useRef(null);
    const targetAddress = useRef(null);

    const navigateTo = (to: string) => {
        navigate(to, { replace: true });
    };

    return (
        <div className="footer-wrapper">
            <div className="top-part">
                <Container>
                    <Row>
                        <div className="col-sm-6 col-lg-4">
                            <div className="information-item">
                                <span className="title">{t('footer_about_title')}</span>
                                <div className="data-container">{t('footer_about_description')}</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                            <div className="information-item">
                                <span className="title">Information</span>
                                <ul className="link-container">
                                    {navigationList.map((item, index) => {
                                        return (
                                            <li
                                                key={index}
                                                aria-hidden="true"
                                                className={pathname == item.to ? 'active' : ''}
                                                onClick={() => navigateTo(item.to)}
                                            >
                                                {item.title}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="information-item">
                                <span className="title">Contact us</span>
                                <div className="contact-container">
                                    <Button
                                        ref={targetTelephone}
                                        className="telephones"
                                        onClick={() =>
                                            setShow({ telephone: !show.telephone, address: false, email: false })
                                        }
                                    >
                                        <i className="fas fa-phone-alt" />
                                    </Button>
                                    <Overlay target={targetTelephone.current} show={show.telephone} placement="bottom">
                                        {(props) => (
                                            <Tooltip id="footer-telephones-tooltip" {...props}>
                                                <ul>
                                                    <li>
                                                        <a href="tel:15157707141">+1(515) 770 7141</a>
                                                    </li>
                                                    <li>
                                                        <a href="tel:18473733497">+1(847) 373 3497</a>
                                                    </li>
                                                </ul>
                                            </Tooltip>
                                        )}
                                    </Overlay>
                                    <Button
                                        ref={targetAddress}
                                        className="address"
                                        onClick={() =>
                                            setShow({ telephone: false, address: !show.address, email: false })
                                        }
                                    >
                                        <i className="fas fa-home" />
                                    </Button>
                                    <Overlay target={targetAddress.current} show={show.address} placement="bottom">
                                        {(props) => (
                                            <Tooltip id="footer-address-tooltip" {...props}>
                                                201 Christina Dr, East Dundee, IL, 60118
                                            </Tooltip>
                                        )}
                                    </Overlay>
                                    <Button
                                        ref={targetEmail}
                                        className="email"
                                        onClick={() =>
                                            setShow({ telephone: false, address: false, email: !show.email })
                                        }
                                    >
                                        <i className="far fa-envelope" />
                                    </Button>
                                    <Overlay target={targetEmail.current} show={show.email} placement="bottom">
                                        {(props) => (
                                            <Tooltip id="footer-email-tooltip" {...props}>
                                                <a href="mailto:info@Attorgmail.com">office@istgroupinc.com</a>
                                            </Tooltip>
                                        )}
                                    </Overlay>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                            <div className="information-item">
                                <span className="title">Social Media</span>
                                <div className="social-container">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/IST-GROUP-Inc-103516245350844"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/ist_group.inc"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            <div className="bottom-part">
                <p>IST GROUP © {new Date().getFullYear()} All Rights Reserved Terms of Use and Privacy Policy</p>
            </div>
        </div>
    );
}

export default Footer;
