import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import './gallery.scss';
import image_1 from '../../assets/images/gallery/1.jpg';
import image_2 from '../../assets/images/gallery/2.jpg';
import image_4 from '../../assets/images/gallery/4.jpg';
import image_5 from '../../assets/images/gallery/5.jpg';
import image_6 from '../../assets/images/gallery/6.jpg';
import image_7 from '../../assets/images/gallery/7.jpg';
import image_8 from '../../assets/images/gallery/8.jpg';
import image_9 from '../../assets/images/gallery/9.jpg';
import image_10 from '../../assets/images/gallery/10.jpg';
import image_11 from '../../assets/images/gallery/11.jpg';
import image_12 from '../../assets/images/gallery/12.jpg';
import image_13 from '../../assets/images/gallery/13.jpg';
import image_14 from '../../assets/images/gallery/14.jpg';
import image_15 from '../../assets/images/gallery/15.jpg';
import image_16 from '../../assets/images/gallery/16.jpg';
import image_17 from '../../assets/images/gallery/17.jpg';

function Gallery() {
    return (
        <div className="gallery">
            <SRLWrapper>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_1} data-attribute="SRL">
                                    <img src={image_1} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_2} data-attribute="SRL">
                                    <img src={image_2} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_4} data-attribute="SRL">
                                    <img src={image_4} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_5} data-attribute="SRL">
                                    <img src={image_5} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_6} data-attribute="SRL">
                                    <img src={image_6} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_7} data-attribute="SRL">
                                    <img src={image_7} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_8} data-attribute="SRL">
                                    <img src={image_8} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_9} data-attribute="SRL">
                                    <img src={image_9} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_10} data-attribute="SRL">
                                    <img src={image_10} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_11} data-attribute="SRL">
                                    <img src={image_11} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_12} data-attribute="SRL">
                                    <img src={image_12} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_13} data-attribute="SRL">
                                    <img src={image_13} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_14} data-attribute="SRL">
                                    <img src={image_14} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_15} data-attribute="SRL">
                                    <img src={image_15} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_16} data-attribute="SRL">
                                    <img src={image_16} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="rounded-lg overflow-hidden">
                                <a href={image_17} data-attribute="SRL">
                                    <img src={image_17} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </SRLWrapper>
        </div>
    );
}

export default Gallery;
