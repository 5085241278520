import React, { useContext, useRef, useState } from 'react';
import { Button, Container, Overlay, Row, Tooltip } from 'react-bootstrap';
import './informationBar.scss';
import i18n from 'i18next';
import { AppContext } from '../../app';
import { useTranslation } from 'react-i18next';

function InformationBar() {
    const { translation, setTranslation } = useContext(AppContext);
    const [show, setShow] = useState({ telephone: false, email: false });
    const targetTelephone = useRef(null);
    const targetEmail = useRef(null);

    const { t } = useTranslation();
    const handleOnclick = (lang: string) => {
        // @ts-ignore
        setTranslation(lang);
        i18n.changeLanguage(lang);
    };

    const activeClass = (lang: string) => (lang === translation ? 'translate-item active' : 'translate-item');

    return (
        <div className="information-bar">
            <Container>
                <Row lg={12}>
                    <div className="top-bar-inner">
                        <div className="left-content">
                            <Button
                                ref={targetTelephone}
                                className="telephones"
                                onClick={() => setShow({ telephone: !show.telephone, email: false })}
                            >
                                <i className="fas fa-phone-alt" />
                            </Button>
                            <Overlay target={targetTelephone.current} show={show.telephone} placement="bottom">
                                {(props) => (
                                    <Tooltip id="telephones-tooltip" {...props}>
                                        <ul>
                                            <li>
                                                <a href="tel:15157707141">+1(515) 770 7141</a>
                                            </li>
                                            <li>
                                                <a href="tel:18473733497">+1(847) 373 3497</a>
                                            </li>
                                        </ul>
                                    </Tooltip>
                                )}
                            </Overlay>
                            <Button
                                ref={targetEmail}
                                className="email"
                                onClick={() => setShow({ telephone: false, email: !show.email })}
                            >
                                <i className="far fa-envelope" />
                            </Button>
                            <Overlay target={targetEmail.current} show={show.email} placement="right">
                                {(props) => (
                                    <Tooltip id="email-tooltip" {...props}>
                                        <a href="mailto:info@Attorgmail.com">office@istgroupinc.com</a>
                                    </Tooltip>
                                )}
                            </Overlay>
                        </div>
                        <div className="right-content">
                            <ul className="translate-list">
                                <li className={activeClass('en')}>
                                    <span className="name" aria-hidden="true" onClick={() => handleOnclick('en')}>
                                        {t('transaction_title_eng')}
                                    </span>
                                </li>
                                <li className={activeClass('ua')}>
                                    <span className="name" aria-hidden="true" onClick={() => handleOnclick('ua')}>
                                        {t('transaction_title_ua')}
                                    </span>
                                </li>
                                <li className={activeClass('ru')}>
                                    <span className="name" aria-hidden="true" onClick={() => handleOnclick('ru')}>
                                        {t('transaction_title_ru')}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    );
}

export default InformationBar;
