export const TRANSLATIONS_RU = {
    first_pros_title: 'НАША РАБОТА - ВАШ УСПЕХ',
    first_pros_content: `Ваш успех - наш успех! Усердная работа и упорный самоотверженный
     труд нашей команды закладывают основу для самых высоких достижений, а также для вашего 
     абсолютного успеха и изобилия. Мы работаем лучше конкурентов и, вы тоже сможете быть 
     сильнее других.`,
    second_pros_title: 'МЫ ГОРДИМСЯ СВОЕЙ КОМАНДОЙ',
    second_pros_content: `Мы являемся транспортной компанией, которая на высоком уровне обслуживает всю континентальную часть США. 
    Наша  работа - быстро, качественно и в максимально короткие сроки  обеспечить вам оптимальные транспортные и логистические услуги, 
    при этом гарантированы: честность, качество и индивидуальный подход к каждому клиенту и деловому партнеру.
    `,
    third_pros_title: 'МЫ ДОБИВАЕМСЯ УСПЕХА',
    third_pros_content: `
       Наша команда состоит из лидеров и преданных своему делу членов команды , совместная работа и сочетание качеств 
       и навыков которых, обеспечат и превзойдут все ваши запросы и требования в современном мире логистики и перевозок. 
       С нами уже перевезли более ...грузов! Начните сотрудничество с перспективной командой профессионалов и вы непременно достигнете успеха и поставленных целей!
    `,
    apply_title: 'РАБОТА ВОДИТЕЛЕМ с cdl и без',
    apply_description_header: 'Мы предлагаем:',
    apply_description_content_one: 'Еженедельную зарплату от 2500 до 3500$',
    apply_description_content_second: 'Гибкий график',
    apply_description_content_third: 'Новие пикапы',
    apply_description_content_fourth: 'Бонусы',
    apply_description_content_fifth: 'Новые прицепы',
    apply_description_content_six: 'Опытные диспетчеры',
    apply_description_content_seven: 'Топливные карты',
    apply_description_content_eight: 'Маршруты Среднего Запада/Восточного побережья.',
    apply_description_content_nine: 'Круглосуточная поддержка 24/7',
    apply_description_content_ten: 'Не требует опыта/обучаем',
    apply_description_content_eleven: 'Оплачиваем отель, если Вы едете на выходные',
    transaction_title_eng: 'АНГ',
    transaction_title_ua: 'УКР',
    transaction_title_ru: 'РУС',
    footer_about_title: 'О нас',
    footer_about_description:
        'Добро пожаловать и благодарим вас за интерес, проявленный к IST GROUP. Inc. Мы коммерческая транспортная компания, которая с гордостью и усердно обслуживает предприятия по всей континентальной части США.',
};
