import { Container, Row } from 'react-bootstrap';
import React from 'react';
import './headerCarousel.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import InformationBar from '../informationBar/informationBar';
import NavigationBar from '../navigationBar/navigationBar';

interface headerCarouselProps {
    slideList: Array<any>;
}

function HeaderCarousel({ slideList }: headerCarouselProps) {
    return (
        <div className="header-wrapper">
            <Container fluid>
                <Row md={12}>
                    <InformationBar />
                    <NavigationBar />
                    <OwlCarousel
                        className="owl-theme carousel"
                        loop
                        margin={30}
                        items={1}
                        autoplay
                        dots={false}
                        nav
                        navElement={'div'}
                        navText={['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>']}
                    >
                        {slideList.map((slide, index) => (
                            <div className="header-area header-background" key={index}>
                                <img src={slide.src} alt="img" />
                                <Container>
                                    <Row lg={8}>
                                        <div className="header-inner">
                                            {slide.title ? <h1 className="title">{slide.title}</h1> : ''}
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        ))}
                    </OwlCarousel>
                </Row>
            </Container>
        </div>
    );
}

export default HeaderCarousel;
