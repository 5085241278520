import React from 'react';
import HeaderCarousel from '../../components/headerCarousel/headerCarousel';
import Layout from '../../components/layout/layout';
import './home.scss';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import Footer from '../../components/footer/footer';
import Map from '../../components/map/map';
import img1 from '../../assets/images/01.jpg';
import img2 from '../../assets/images/02.jpg';
import img3 from '../../assets/images/03.jpg';

function Home() {
    const { t } = useTranslation();

    const slides = [
        {
            src: img1,
            title: '',
        },
        {
            src: img2,
            title: '',
        },
        {
            src: img3,
            title: '',
        },
    ];

    return (
        <Layout
            header={<HeaderCarousel slideList={slides} />}
            content={
                <div className="content-wrapper">
                    <Container fluid>
                        <Row>
                            <div className="points">
                                <Row>
                                    <div className="col-md-12 col-lg-6">
                                        <div className="point-item">
                                            <div className="icon">
                                                <i className="flaticon-online-service" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">{t('first_pros_title')}</h4>
                                                <p>{t('first_pros_content')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-6">
                                        <div className="point-item">
                                            <div className="icon">
                                                <i className="flaticon-map-4"></i>
                                            </div>
                                            <div className="content">
                                                <h4 className="title">{t('second_pros_title')}</h4>
                                                <p>{t('second_pros_content')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-12">
                                        <div className="point-item">
                                            <div className="icon">
                                                <i className="flaticon-booking"></i>
                                            </div>
                                            <div className="content">
                                                <h4 className="title">{t('third_pros_title')}</h4>
                                                <p>{t('third_pros_content')}</p>
                                            </div>
                                        </div>
                                        {/*<div className="point-item">*/}
                                        {/*    <div className="icon">*/}
                                        {/*        <i className="flaticon-businessman"></i>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="content">*/}
                                        {/*        <h4 className="title">{t('fourth_pros_title')}</h4>*/}
                                        {/*        <p>{t('fourth_pros_content')}</p>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </Row>
                            </div>
                        </Row>
                    </Container>
                    <div className="map">
                        <Map />
                    </div>
                </div>
            }
            footer={<Footer />}
        />
    );
}

export default Home;
