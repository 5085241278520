export const TRANSLATIONS_UA = {
    first_pros_title: 'ВАШ УСПІХ - НАШ УСПІХ',
    first_pros_content:
        'Невпинні зусилля, та самовіддана праця нашої команди закладають основу для вашого абсолютного успіху та достатку. Ми працюємо краще за конкурентів і, ви теж зможете бути сильнішими за інших.',
    second_pros_title: 'МИ В ЗАХВАТІ ВІД НАШОЇ КОМАНДИ',
    second_pros_content: `
    Ми є транспортною компанією, що з гордістю і відповідальністю обслуговує клієнтів по всій континентальній 
       частині США. Ми відносимось до кожного перевезення зі скрупульозною увагою до деталей, щоб ваші потреби були 
       задоволені на найвищому рівні. Ми є компанією де навчання і самовдосконалення ніколи не припиняються, ми 
       компанія, яка пропонує не просто роботу а мету.
    `,
    third_pros_title: 'МИ ДОБИВАЄМОСЬ УСПІХУ',
    third_pros_content: `
        Наша місія проста - незалежно від ваших транспортних потреб, наша робота завжди гарантувати бездоганні послуги,
         щоб ви могли отримати безпрецидентний успіх. Наша єдина мета - забезпечити найвищий рівень транспортних послуг, 
         гарантуючи при цьому чесність, справедливість, старанність і ввічливість до наших клієнтів і ділових партнерів. 
         Наша компанія складається з лідерів і відданих членів команди, співпраця і поєднання рис і здібностей яких, 
         забезпечать і перевершать всі ваші потреби і побажання в перевезеннях і гарантуватимуть продуктивність на 
         найвищому рівні.
    `,
    apply_title: 'РОБОТА ВОДІЄМ з cdl і без',
    apply_description_header: 'Ми пропонуємо:',
    apply_description_content_one: 'Щотижневу зарплату від 2500 до 3500 $',
    apply_description_content_second: 'Гнучкий графік',
    apply_description_content_third: ' Нові пікапи',
    apply_description_content_fourth: 'Бонуси',
    apply_description_content_fifth: 'Нові причепи',
    apply_description_content_six: 'Досвідчені диспетчери',
    apply_description_content_seven: 'Паливні карти',
    apply_description_content_eight: 'Маршрути Середнього Заходу/Східного узбережжя.',
    apply_description_content_nine: 'Цілодобова підтримка 24/7',
    apply_description_content_ten: 'Не вимагає досвіду/навчаємо',
    apply_description_content_eleven: 'Оплачуємо готель, якщо Ви їдете на вихідні',
    transaction_title_eng: 'АНГ',
    transaction_title_ua: 'УКР',
    transaction_title_ru: 'РУС',
    footer_about_title: 'Про нас',
    footer_about_description:
        'Вітаємо та дякуємо за інтерес до IST GROUP. Inc. Ми комерційна транспортна компанія, яка з гордістю та старанно обслуговує підприємства по всій континентальній частині США.',
};
